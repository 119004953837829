<div class="container">
    <div class="row">
        <div class="col-md-3">
            <a routerLink="/" class="app-logo">
            <img src="../../../../assets/img/tol-logo.svg" class="img-fluid" /></a>
        </div>
    </div>
</div>

<section class="page-not-found-sec">
<div class="container">
    <div class="row">
        <div class="col-lg-6">
            <img src="../../../../assets/img/page-not-found.svg" class="img-fluid" />
        </div>
        <div class="col-lg-6">
            <div class="system-img">
                <iframe width="420" height="315" src="https://www.youtube.com/embed/RLxMr-f-zUc?rel=0&autoplay=1&mute=0">
                </iframe>
            </div>
        </div>
    </div>
</div>
</section>