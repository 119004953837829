<section class="home-banner text-center">
  <div class="container">
    <div class="banner-flex">
      <div class="women-art"><img src="assets/img/women-art.svg" alt=""></div>
      <div class="banner-cont-sec">
        <header class="title-head">
          <h3 class="title-lg">Leading Tech Hiring Platform Connecting Top IT Talent with Businesses</h3>
          <!-- <p style="font-size: 13px;margin-top: -20px;color: #ED4542;font-weight: bold;">Your Business success is determined by the talent you bring on board. Embrace the future with our cutting-edge tech hiring platform.</p> -->
          <p class="subheading">TalentOnLease is one of the fastest tech hiring platforms in India. We promise to deliver the 'Right Resources' at the 'Right Time' and at the 'Right Cost' within 24-48 hours.</p>
        </header>
        <div class="cta">
          <a routerLink="/find-candidates" class="btn btn-primary pr65">Find Candidates</a>
          <a routerLink="/find-jobs" class="btn btn-primary pr65">Find Jobs</a>
          <a href="https://calendly.com/dayaprakash/15min" target="_blank" class="btn btn-primary pr65">Request a Consultation</a>
        </div>
        <!-- <div class="home-featured">
          <p>Featured in :</p>
          <span class="featured-pic"><img src="assets/img/financial-express.png" alt=""></span>
          <span class="featured-pic"><img src="assets/img/techgig.png" alt=""></span>
          <span class="featured-pic"><img src="assets/img/business-world.png" alt=""></span>
          <span class="featured-pic"><img src="assets/img/indian-express.png" alt=""></span>
          <span class="featured-pic"><img src="assets/img/express-computer.png" alt=""></span>
        </div> -->
        <div class="container">
          <header class="gradient-title-head">
            <h2 class="title-lg">Trusted by <span>Leading Companies</span></h2>
          </header>
          <div class="trusted-col">
            <div class="item"  *ngFor="let leadingcompany of leadingCompanies" >
              <div class="sec-pic"><img src={{leadingcompany.logo}} alt={{leadingcompany.name}}></div>
            </div>
          </div>
        </div>
      </div>
      <div class="men-art"><img src="assets/img/men-art.svg" alt=""></div>
    </div>
  </div>
</section>

<section class="client-partner-sec">
  <div class="container">
    <div class="client-partner-col">
      <div class="parcol">
        <div class="sec-pic"><img src="assets/img/verified-client.svg" alt=""></div>
        <div class="sec-cont">
          <span>Verified Clients</span>
          <p>{{totalClientCount}} +</p>
        </div>
      </div>
      <div class="parcol">
        <div class="sec-pic"><img src="assets/img/verified-partner.svg" alt=""></div>
        <div class="sec-cont">
          <span>Verified Partners</span>
          <p>{{totalPartnerCount}} +</p>
        </div>
      </div>
      <div class="parcol">
        <div class="sec-pic"><img src="assets/img/job-position.svg" alt=""></div>
        <div class="sec-cont">
          <span>Job Positions</span>
          <p>{{totaljobsPositionCount}} +</p>
        </div>
      </div>
      <div class="parcol">
        <div class="sec-pic"><img src="assets/img/qualifies-candidates.svg" alt=""></div>
        <div class="sec-cont">
          <span>Qualified Candidates</span>
          <p>{{totalCandidatesCount}} +</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section trusted-companies text-center pb-0">
  <div class="container">
  <div class="home-featured">
    <p>Featured in :</p>
    
    <!-- <span><h1>Featured in :</h1></span> -->
    <span class="featured-pic"><img src="assets/img/financial-express.png" alt=""></span>
    <span class="featured-pic"><img src="assets/img/techgig.png" alt=""></span>
    <span class="featured-pic"><img src="assets/img/business-world.png" alt=""></span>
    <span class="featured-pic"><img src="assets/img/indian-express.png" alt=""></span>
    <span class="featured-pic"><img src="assets/img/express-computer.png" alt=""></span>
  </div>
</div>
  <!-- <div class="container">
    <header class="gradient-title-head">
      <h2 class="title-lg">Trusted by <span>Leading Companies</span></h2>
    </header>
    <div class="trusted-col">
      <div class="item"  *ngFor="let leadingcompany of leadingCompanies" >
        <div class="sec-pic"><img src={{leadingcompany.logo}} alt={{leadingcompany.name}}></div>
      </div>
    </div>
  </div> -->
</section>

<section class="section talent-lease-sec pb-0">
  <div class="container">
    <div class="talent-lease-col">
      <header class="gradient-title-head">
        <div class="title-lg mb-3">Why <span>TalentOnLease?</span></div>
        <p>Great things happen when great people come together. It’s magic!</p>
      </header>
      <div class="talent-col">
        <div class="sec-pic"><img src="assets/img/talent-person.png" alt=""></div>
        <div class="sec-cont">
          <div class="col-sec">
            <div class="col-sec-pic"><img src="assets/img/strong-founding.svg" alt=""></div>
            <div class="col-sec-cont">Active bench of {{totalCandidatesCount}}</div>
          </div>
          <div class="col-sec">
            <div class="col-sec-pic"><img src="assets/img/vetted-professionals.svg" alt=""></div>
            <div class="col-sec-cont">Verified and vetted IT professionals</div>
          </div>
          <div class="col-sec">
            <div class="col-sec-pic"><img src="assets/img/acquisition-platform.svg" alt=""></div>
            <div class="col-sec-cont">Available Within 24 to 48 hours</div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section community-sec pb-0">
  <div class="container">
    <header class="gradient-title-head text-center">
      <div class="title-lg mb-3">Reviews from <span>Community</span></div>
      <p>Don't take our word for it, see what the community is saying. <br>Here’s what they have to say about us.</p>
    </header>
    <div class="slider-info dotscro">
      <owl-carousel-o [options]="customOptions" >
        <ng-template carouselSlide  *ngFor="let reviewAndComunity of reviewAndComunities" >
          <div class="item" *ngIf=" reviewAndComunity.videoUrl=='' " >
            <div class="content" >
              <p>{{reviewAndComunity.comments}}</p>
            </div>
            <div class="comusr">
              <div class="sec-pic"><img src="assets/img/pic1.jpg" alt=""></div>
              <div class="usercol">
                <span class="usrname">{{reviewAndComunity.name}}</span>
                <span class="usrcomp">{{reviewAndComunity.company}}</span>
              </div>
            </div>
            
          </div>
          <div class="item"  *ngIf="reviewAndComunity.videoUrl!='null' && reviewAndComunity.videoUrl!='' ">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe width="560" height="300" src="https://www.youtube.com/embed/wi-MGFhrad0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
        </ng-template>
       
      </owl-carousel-o>
    </div>
  </div>
</section>

<section class="section for-client">
  <span id="spnJoin" (click)="scroll(targetJoin)" style="display:none;"></span>
  <div class="container" #targetJoin>
    <header class="gradient-title-head text-center">
      <div class="title-lg mb-3"><b>Join Our Developer Recruitment Platform and Be a Part of This Great Community</b></div>
      <p>TalentOnLease, an Tech Recruiting Platform, works with a wide range of partners worldwide, including IT services, solution firms, and individual programs in our team.</p>
      <p>Any new candidate is accepted only after rigorous tests of their expertise and a personal interview to check if they are solely ready for your projects.</p>
    </header>
    <div class="row">
      <div class="col-md-6">
        <div class="column-pic"><img src="assets/img/great-community.png" alt=""></div>
      </div>
      <div class="col-md-6">
        <div class="join-us-sec pr-0">
          <!-- <div class="shead">For Clients</div> -->
          <div class="title-lg mb-3">Find Resources for your Project Requirements</div>
          <p>Struggling to find the Right One? </p>
          <p>Our Tech Hiring Platform offers flexibility as well as reliability with a team of skilled experts. Our sole mission is to connect our clients with rightful partners for their critical projects without the liability of hiring IT staff under full-time employment contracts. 
          </p>
          <ul>
            <li>Meet critical project requirements, faster</li>
            <li>IT recruitment under ‘Pay as you use’ model</li>
            <li>Optimize resource planning</li>
            <li>Shorten TAT for critical projects</li>
            <li>Avoid in house competency building in non-core operations</li>
            <li>Operate with lean teams, without worrying about attrition</li>
          </ul>
          <div class="cta">
            <a href="javascript:void(0)" (click)="scroll(target)" class="btn btn-secondary">See How it works</a>
            <a routerLink="/find-candidates" class="btn btn-primary pr65">Hire Candidate</a>
            <a href="https://calendly.com/dayaprakash/15min" target="_blank" class="btn btn-primary pr65">Request a Consultation</a>
          </div>
        </div>
      </div>
    </div>
    <div class="candidate-listing-col">
      <header class="mb-4">
        <div class="head d-flex align-items-center">
          <div class="title-md">Find Candidates</div>
          <span class="total" *ngIf="totalCandidate!=0">{{totalCandidate}}+</span>
          <span class="total" *ngIf="totalCandidate==0">{{totalCandidate}}</span>
          <a routerLink="/find-candidates" class="btn btn-primary btn-sm">View All</a>
        </div>
        <div class="row align-items-center searchtoolbar">
          <div class="form-group">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>Search Skills</mat-label>
              <input type="text"
              placeholder="Search"
              aria-label="Search"
              matInput    
             [formControl]="myControl"
              [matAutocomplete]="auto"  (change)="selectedCandidate(myControl)" (keypress)="spaceValidation($event)">
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
               <mat-option *ngFor="let option of filteredOptions | async" [value]="option.text" (click)="selectedCandidate(option)">
                 {{option.text}}
               </mat-option>
             </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field appearance="fill">
              <mat-label>Min Exp.</mat-label>
              <mat-select  [(ngModel)]="candidateSearch.minExp">
                <mat-option *ngFor="let exp of experimence" [value]="exp"  (click)="getallCandidates(candidateSearch)">{{exp}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field appearance="fill">
              <mat-label>Max Exp.</mat-label>
              <mat-select [(ngModel)]="candidateSearch.maxExp">
                <mat-option *ngFor="let exp of experimence" (click)="getallCandidates(candidateSearch)" [value]="exp">{{exp}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </header>
      <div class="candidate-listing-sec dotscro">
        <owl-carousel-o [options]="forClient"  *ngIf="candidateshow" >
        
          <ng-template carouselSlide  *ngFor="let candidate of candidates">
            <div class="column-card">
              <a (click)="CandidateDetail(candidate.id)" class="list-sec d-flex">
                <div class="sec-pic"><img src="assets/img/pic1.jpg" alt=""></div>
                <div class="list-user">
                  <div class="uname">{{candidate.fullName}}  <span><i   [ngClass]="[ candidate.isVerified ? 'verify-ico verified' : 'verify-ico']" >&nbsp;</i></span></div>
                  <div class="udesg">{{candidate.designation}}</div>
                </div>
              </a>
              <div class="uterm d-flex">
                <span class="uyrs"><img src="assets/img/job-exp-blk.svg" alt="">  {{candidate.experience}} Years</span>
                <span class="uloc"><img src="assets/img/job-loc-blk.svg" alt=""> {{candidate.cityName}}</span>
              </div>
            </div>
          </ng-template>
      
        </owl-carousel-o>
        <div *ngIf="!candidateshow" class="lising-col job">
          No Candidate found
           </div>
      </div>
    </div>
  </div>
</section>

<section class="section for-partner">
  <div class="container">
    <div class="row row-reverse">
      <div class="col-md-6">
        <div class="column-pic"><img src="assets/img/partner-staff.png" alt=""></div>
      </div>
      <div class="col-md-6 pr-0">
        <div class="join-us-sec">
          <!-- <div class="shead">For Partners</div> -->
          <div class="title-lg mb-3">Find Jobs for your Bench Resources/Staff</div>
          <p>Get partnered with us, and create your resource profiles with expertise. As a partner, you will be able to map out newer productive projects and start deploying your benched staff. </p>
          <ul>
            <li>Minimum bench strength to boost revenue.</li>
            <li>Diversify Your Portfolio with emerging technologies</li>
            <li>Optimisation of resource utilisation</li>
            <li>Upskill your bench with new challenges & resources.</li>
            <li>Discover new business opportunities in your domain.</li>
            <li>Incentivise employees with exposure to new projects.</li>
          </ul>
          <div class="cta">
            <a  href="javascript:void(0)" (click)="scroll(target)" class="btn btn-secondary">See How it works</a>
            <a href="#" class="btn btn-primary pr65">Get Started</a>
            <a href="https://calendly.com/dayaprakash/15min" target="_blank" class="btn btn-primary pr65">Request a Consultation</a>
          </div>
        </div>
      </div>
    </div>
    <div class="candidate-listing-col">
      <header class="mb-4">
        <div class="head d-flex align-items-center">
          <h3 class="title-md">Find New Jobs</h3>
          <span class="total" *ngIf="totaljobs!=0">{{totaljobs}}+</span>
          <span class="total" *ngIf="totaljobs==0">{{totaljobs}}</span>
          <a routerLink="/find-jobs" class="btn btn-primary btn-sm">View All</a>
        </div>
        <div class="d-flex align-items-center searchtoolbar">
          <div class="form-group">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>Search Skills</mat-label>
              <input type="text"
              placeholder="Search"
              aria-label="Search"
              matInput    
             [formControl]="jobControl"
              [matAutocomplete]="autos"  (change)="selectedJob(jobControl)" (keypress)="spaceValidation($event)">
              <mat-autocomplete autoActiveFirstOption #autos="matAutocomplete" >
               <mat-option *ngFor="let option of filteredjobOptions | async" [value]="option.text" (click)="selectedJob(option)">
                 {{option.text}}
               </mat-option>
             </mat-autocomplete>
             
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field appearance="fill">
              <mat-label>Min Exp.</mat-label>
              <mat-select  [(ngModel)]="jobsearch.minExp">
                <mat-option *ngFor="let exp of experimence" [value]="exp"  (click)="getallJobs(jobsearch)">{{exp}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field appearance="fill">
              <mat-label>Max Exp.</mat-label>
              <mat-select [(ngModel)]="jobsearch.maxExp">
                <mat-option *ngFor="let exp of experimence" (click)="getallJobs(jobsearch)" [value]="exp">{{exp}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </header>
      <div class="clising-col dotscro find-new-job-slide">
        <owl-carousel-o [options]="forPartner"  *ngIf="jobshow">
        
          <ng-template carouselSlide *ngFor="let job of jobs"   >
            <div class="lising-col">
              <a href="#" class="collis">
                <div class="job-tags mb-3">
                  <span>{{job?.positionType}}</span>
                  <!-- <span>{{job?.technology}}</span> -->
                </div>
                <div class="job-desc">
                  <div class="job-detail">
                    <div class="job-title">{{job?.technology == null ? job?.jobTitle : job?.technology}}</div>
                    <div class="job-comp"></div>
                    <div class="job-loct mt-2 mb-2">
                      <span class="job-exp"><i class="fa fa-briefcase"></i>  {{job?.minExp}}-{{job?.maxExp}} Years</span>
                      <span class="job-loc"><i class="fa fa-map-marker"></i> {{job?.cityName}}</span>
                    </div>
                  </div>
                </div>
                <div class="job-expert">
                  {{job.skills}}
                </div>
              </a>
              <div class="apply-job">
                <a routerLink="/find-jobs" class="btn btn-transparent btn-sm">View Details</a>
                <a  (click)="applyJob(job.id,job.technologyId)"  class="btn btn-outline btn-sm">Apply</a>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
        <div *ngIf="!jobshow" class="lising-col job">
       No Jobs found
        </div>
        <div #target></div>
      </div>
    </div>
  </div>
</section>


<section class="section for-client">
  <span id="spnJoin" (click)="scroll(targetJoin)" style="display:none;"></span>
  <div class="container" #targetJoin>
   
    <div class="row">
      <div class="col-md-6">
        <div class="column-pic"><img src="assets/img/great-community.png" alt=""></div>
      </div>
      <div class="col-md-6">
        <div class="join-us-sec pr-0">
          <!-- <div class="shead">For Clients</div> -->
          <div class="title-lg mb-3">Why Choose TalentOnLease as your Tech Hiring Platform?</div>
          <p>Struggling to find the Right One? </p>
          <p>TalentOnLease works on the policy of the Right Resources at the Right Time at the Right Cost. Speed is the biggest asset in today’s fast-forward business market, and that’s what we offer you as an IT recruitment platform. Our partners are well trained, qualified, and selected after a round of rigorous tests meeting the requirements of real-time industry. 
          </p>
          <ul>
            <li>Access to 9000+ Qualified Candidates</li>
            <li>738+ Verified Clients Worldwide</li>
            <li>171+ Verified Partners</li>
            <li>24-48 Hours Speed Hiring.</li>
            <li>Regular Reporting & Communication</li>
            <li>Full Signed NDA Agreement.</li>
            <li>No Hidden Pricing Slabs</li>
          </ul>
          
          <div class="row email-box-main">
            <div class="col-md-6 mx-auto">
                <button style="Border: 1px solid red" mat-raised-button (click)="openDialog()" class="btn mb-15">Start Hiring</button>
            </div>
          </div>
          
          <!-- <div class="cta">
            <button mat-raised-button (click)="openDialog()" class="btn btn-secondary">Start Hiring</button>
          </div> -->
        </div>
      </div>
    </div>
    <!-- <div class="candidate-listing-col">
      <header class="mb-4">
        <div class="head d-flex align-items-center">
          <div class="title-md">Find Candidates</div>
          <span class="total" *ngIf="totalCandidate!=0">{{totalCandidate}}+</span>
          <span class="total" *ngIf="totalCandidate==0">{{totalCandidate}}</span>
          <a routerLink="/find-candidates" class="btn btn-primary btn-sm">View All</a>
        </div>
        <div class="row align-items-center searchtoolbar">
          <div class="form-group">
            <mat-form-field class="full-width" appearance="fill">
              <mat-label>Search Skills</mat-label>
              <input type="text"
              placeholder="Search"
              aria-label="Search"
              matInput    
             [formControl]="myControl"
              [matAutocomplete]="auto"  (change)="selectedCandidate(myControl)" (keypress)="spaceValidation($event)">
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
               <mat-option *ngFor="let option of filteredOptions | async" [value]="option.text" (click)="selectedCandidate(option)">
                 {{option.text}}
               </mat-option>
             </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field appearance="fill">
              <mat-label>Min Exp.</mat-label>
              <mat-select  [(ngModel)]="candidateSearch.minExp">
                <mat-option *ngFor="let exp of experimence" [value]="exp"  (click)="getallCandidates(candidateSearch)">{{exp}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field appearance="fill">
              <mat-label>Max Exp.</mat-label>
              <mat-select [(ngModel)]="candidateSearch.maxExp">
                <mat-option *ngFor="let exp of experimence" (click)="getallCandidates(candidateSearch)" [value]="exp">{{exp}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </header>
      <div class="candidate-listing-sec dotscro">
        <owl-carousel-o [options]="forClient"  *ngIf="candidateshow" >
        
          <ng-template carouselSlide  *ngFor="let candidate of candidates">
            <div class="column-card">
              <a (click)="CandidateDetail(candidate.id)" class="list-sec d-flex">
                <div class="sec-pic"><img src="assets/img/pic1.jpg" alt=""></div>
                <div class="list-user">
                  <div class="uname">{{candidate.fullName}}  <span><i   [ngClass]="[ candidate.isVerified ? 'verify-ico verified' : 'verify-ico']" >&nbsp;</i></span></div>
                  <div class="udesg">{{candidate.designation}}</div>
                </div>
              </a>
              <div class="uterm d-flex">
                <span class="uyrs"><img src="assets/img/job-exp-blk.svg" alt="">  {{candidate.experience}} Years</span>
                <span class="uloc"><img src="assets/img/job-loc-blk.svg" alt=""> {{candidate.cityName}}</span>
              </div>
            </div>
          </ng-template>
      
        </owl-carousel-o>
        <div *ngIf="!candidateshow" class="lising-col job">
          No Candidate found
           </div>
      </div>
    </div> -->
  </div>
</section>

<section class="section how-works-sec" >
  <div class="container">
    <header class="gradient-title-head">
      <h4 class="title-lg">How it <span>Works?</span></h4>
    </header>
    <div class="tabbing-sec">
      <mat-tab-group>
        <mat-tab label="For Clients">
          <div class="tabbing-body">
            <div class="client-column">
              <div class="steps-col step-one">
                <div class="sec-pic"><img src="assets/img/step-one-pic.jpg" alt=""></div>
                <div class="sico">Step 1</div>
                <div class="desc">Create your Profile on the <br>TalentOnLease Platform</div>
              </div>
              <div class="steps-col">
                <div class="sec-pic"><img src="assets/img/step2.svg" alt=""></div>
                <div class="sico">Step 2</div>
                <div class="desc">Share Project <br>Details</div>
              </div>
              <div class="steps-col">
                <div class="sec-pic"><img src="assets/img/step3.svg" alt=""></div>
                <div class="sico">Step 3</div>
                <div class="desc">Find Resources <br>From TOL</div>
              </div>
              <div class="steps-col">
                <div class="sec-pic"><img src="assets/img/step4.svg" alt=""></div>
                <div class="sico">Step 4</div>
                <div class="desc">Assess Skills</div>
              </div>
              <div class="steps-col">
                <div class="sec-pic"><img src="assets/img/step5.svg" alt=""></div>
                <div class="sico">Step 5</div>
                <div class="desc">Sign a Contract</div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="For Partners">
          <div class="tabbing-body">
            <div class="client-column">
              <div class="steps-col step-one">
                <div class="sec-pic"><img src="assets/img/step-one-pic.jpg" alt=""></div>
                <div class="sico">Step 1</div>
                <div class="desc">Create your Profile on the <br>TalentOnLease Platform</div>
              </div>
              <div class="steps-col">
                <div class="sec-pic"><img src="assets/img/step2.svg" alt=""></div>
                <div class="sico">Step 2</div>
                <div class="desc">Share Profile on <br>Bench Staff</div>
              </div>
              <div class="steps-col">
                <div class="sec-pic"><img src="assets/img/step3.svg" alt=""></div>
                <div class="sico">Step 3</div>
                <div class="desc">Share Projects for <br>The Bench Staff</div>
              </div>
              <div class="steps-col">
                <div class="sec-pic"><img src="assets/img/step4.svg" alt=""></div>
                <div class="sico">Step 4</div>
                <div class="desc">Got Connected to <br>The Client</div>
              </div>
              <div class="steps-col">
                <div class="sec-pic"><img src="assets/img/step5.svg" alt=""></div>
                <div class="sico">Step 5</div>
                <div class="desc">Sign a Contract</div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
      <div class="cta">
        <a href="#" class="btn btn-primary pr65">Get Started</a>
      </div>
    </div>
  </div>
</section>

<section class="section faq-sec">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="hire-cat mb-4">
          <div class="title-md">Hire from 50+ <span>Job Categories</span></div>
          <div class="marquee">
            <div class="marquee--inner">
              <span class="marqee-scroll">
                <div *ngFor="let job of jobCategories" class="orb">{{job.jobTitle}}</div>

              </span>
              <span class="marqee-scroll">
                <div *ngFor="let job of jobCategories" class="orb">{{job.jobTitle}}</div>

              </span>
            </div>
          </div>
        </div>
        <div class="hire-cat  mb-4">
          <div class="title-md">Hire from 50+ <span>Skill Technologies</span></div>
          <div class="marquee-sec">
            <div class="marquee--inner">
              <span class="marqee-scroll">

                <div *ngFor="let technology of technologies" class="orb">{{technology.text}}</div>
              </span>
              <span class="marqee-scroll">
                <div *ngFor="let skill of skills" class="orb">{{skill.text}}</div>
              </span>
              <span class="marqee-scroll">
                <div *ngFor="let technology of technologies" class="orb">{{technology.text}}</div>
              </span>
              <span class="marqee-scroll">
                <div *ngFor="let skill of skills" class="orb">{{skill.text}}</div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="faq-column">
          <header class="gradient-title-head">
            <div class="title-lg">Frequently Asked <span>Questions</span></div>
          </header>
          <mat-accordion>

            <mat-expansion-panel *ngFor="let fa of faqs">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{fa?.question}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>{{fa?.answer}}</p>
            </mat-expansion-panel>

          </mat-accordion>
          <div class="cta mt-5">
            <a routerLink="/faqs" class="btn btn-primary pr65">View All FAQs</a>
          </div>
        </div>
      </div>

    </div>
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">
    <a href="https://api.whatsapp.com/send?phone=9899240555&text=Hello%20TalentOnLease%20team,%0A%0AI'm%20reaching%20out%20to%20discuss%20our%20organization's%20talent%20acquisition%20needs.%20We're%20interested%20in%20learning%20more%20about%20your%20services%20and%20how%20you%20can%20assist%20us.%0A%0ACould%20you%20please%20provide%20more%20information%20or%20schedule%20a%20call%20at%20your%20earliest%20convenience?%0A%0AName : %0ADesignation: %0ACompany Name: " class="float whatsapp-icon" target="_blank">
      <i class="fa fa-whatsapp my-float"></i>
    </a>
    <!--<div class="row">
      <div class="col-sm-10"></div>
      <div class="col-sm-2">
        <span style="color: #16ef16; font-size: 44px;">
          <i class="fa fa-whatsapp" aria-hidden="true"></i>
        </span>
      </div>
    </div>-->
  </div>
</section>
