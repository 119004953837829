import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './Components/pages/about/about.component';
import { ContactComponent } from './Components/pages/contact/contact.component';
import { FindCandidatesComponent } from './Components/pages/find-candidates/find-candidates.component';
import { FindJobsComponent } from './Components/pages/find-jobs/find-jobs.component';
import { HomeComponent } from './Components/pages/home/home.component';
import { LoginComponent } from './Components/pages/login/login.component';
import { PagenotfoundComponent } from './Components/pages/pagenotfound/pagenotfound.component';
import { RegisterComponent } from './Components/pages/register/register.component';
import { ResourcesComponent } from './Components/pages/resources/resources.component';
import { AdminlayoutComponent } from './Components/layouts/admin/adminlayout/adminlayout.component';
import { SitelayoutComponent } from './Components/layouts/site/sitelayout/sitelayout.component';
import { ForgetPasswordComponent } from './Components/pages/forget-password/forget-password.component';
import { FaqsComponent } from './Components/pages/faqs/faqs.component';
import { RecordingComponent } from './Components/pages/recording/recording.component';
import { InstructionsComponent } from './Components/pages/instructions/instructions.component';
import { ThankyouComponent } from './Components/pages/thankyou/thankyou.component';
import { PartnerlayoutComponent } from './Components/layouts/partner/partnerlayout/partnerlayout.component';
import { ConferenceComponent } from './Components/pages/conference/conference.component';
import { SSOLoginComponent } from './Components/pages/sso-login/sso-login.component';
import { TermConditionComponent } from './Components/pages/term-condition/term-condition.component';
import { PrivacyPolicyComponent } from './Components/pages/privacy-policy/privacy-policy.component';
import { Homev2Component } from './Components/pages/homev2/homev2.component';
import { FullStackDeveloperComponent } from './Components/pages/full-stack-developer/full-stack-developer.component';
import { CampaignComponent } from './Components/pages/campaign/campaign.component';
import { CampaignThankyouComponent } from './Components/pages/campaign/compaign.thankyou.component';
import { PythonDeveloperComponent } from './Components/pages/python-developer/python-developer.component';
import { JavaDeveloperComponent } from './Components/pages/java-developer/java-developer.component';
import { MobileAppDeveloperComponent } from './Components/pages/mobile-app-developer/mobile-app-developer.component';
import { SoftwareDeveloperComponent } from './Components/pages/software-developer/software-developer.component';
import { DotNetDeveloperComponent } from './Components/pages/dot-net-developer/dot-net-developer.component';
import { PythonDeveloperCampaignComponent } from './Components/pages/python-developer-campaign/python-developer-campaign.component';
import { JavaDeveloperCampaignComponent } from './Components/pages/java-developer-campaign/java-developer-campaign.component';
import { MobileAppDeveloperCampaignComponent } from './Components/pages/mobile-app-developer-campaign/mobile-app-developer-campaign.component';
import { SoftwareDeveloperCampaignComponent } from './Components/pages/software-developer-campaign/software-developer-campaign.component';
import { DotNetDeveloperCampaignComponent } from './Components/pages/dot-net-developer-campaign/dot-net-developer-campaign.component';
import { FullStackDeveloperCampaignComponent } from './Components/pages/full-stack-developer-campaign/full-stack-developer-campaign.component';
import { AngularDeveloperComponent } from './Components/pages/angular-developer/angular-developer.component';
import { AngularDeveloperCampaignComponent } from './Components/pages/angular-developer-campaign/angular-developer-campaign.component';
import { MeanStackDeveloperComponent } from './Components/pages/mean-stack-developer/mean-stack-developer.component';
import { MeanStackDeveloperCampaignComponent } from './Components/pages/mean-stack-developer-campaign/mean-stack-developer-campaign.component';
import { ReactJsDeveloperComponent } from './Components/pages/react-js-developer/react-js-developer.component';
import { ReactJsDeveloperCampaignComponent } from './Components/pages/react-js-developer-campaign/react-js-developer-campaign.component';
import { MernStackDeveloperComponent } from './Components/pages/mern-stack-developer/mern-stack-developer.component';
import { MernStackDeveloperCampaignComponent } from './Components/pages/mern-stack-developer-campaign/mern-stack-developer-campaign.component';
import { PromotionComponent } from './Components/pages/promotion/promotion.component';
import { ApplyMatchedCandidateComponent } from './Components/pages/apply-matched-candidate/apply-matched-candidate.component';
import { TenantResolver } from './_services/tenant.resolver';
import { FindApplyJobComponent } from './Components/pages/find-apply-job/find-apply-job.component';

const routes: Routes = [
  { path: 'campaign', component: CampaignComponent },
  { path: 'thank-you', component: CampaignThankyouComponent },
  { path: 'full-stack-developer', component: FullStackDeveloperCampaignComponent },
  { path: 'python-developer', component: PythonDeveloperCampaignComponent },
  { path: 'java-developer', component: JavaDeveloperCampaignComponent },
  { path: 'mobile-app-developer', component: MobileAppDeveloperCampaignComponent },
  { path: 'software-developer', component: SoftwareDeveloperCampaignComponent },
  { path: 'dot-net-developer', component: DotNetDeveloperCampaignComponent },
  { path: 'angular-developer', component: AngularDeveloperCampaignComponent },
  { path: 'mean-stack-developer', component: MeanStackDeveloperCampaignComponent },
  { path: 'react-js-developer', component: ReactJsDeveloperCampaignComponent },
  { path: 'mern-stack-developer', component: MernStackDeveloperCampaignComponent },
  { path: 'apply/:job/:candidate', component: ApplyMatchedCandidateComponent },
  {
    path: '',
    component: SitelayoutComponent,
    //resolve: { data: TenantResolver },
    children: [
      {
        path: '', component: HomeComponent, data: { title: 'Best IT Staffing and Recruiting Agency - TalentOnLease' }
      },
      { path: 'home', component: HomeComponent, data: { title: 'Best IT Staffing and Recruiting Agency - TalentOnLease' } },
      { path: 'index', component: Homev2Component, data: { title: 'Best IT Staffing and Recruiting Agency - DigiRrecruitnet' } },
      { path: 'intro', component: PromotionComponent },
      { path: 'hire-full-stack-developer', component: FullStackDeveloperComponent, data: { title: 'Hire Full Stack Developer | Find Skilled IT Professionals' } },
      { path: 'hire-python-developer', component: PythonDeveloperComponent, data: { title: 'Hire Certified Python Developer With in 2 Days | TalentOnLease' } },
      { path: 'hire-java-developer', component: JavaDeveloperComponent, data: { title: 'Hire Dedicated Java Developers | TalentOnLease' } },
      { path: 'hire-mobile-app-developer', component: MobileAppDeveloperComponent, data: { title: 'Hire Best Mobile App Developer in India | TalentOnLease' } },
      { path: 'hire-software-developer', component: SoftwareDeveloperComponent, data: { title: 'Hire Talented Software Developer in Easy Steps | Hire Now' } },
      { path: 'hire-dot-net-developer', component: DotNetDeveloperComponent, data: { title: 'Hire Dedicated .NET Developers | TalentOnLease Hire Now' } },
      { path: 'hire-angular-developer', component: AngularDeveloperComponent, data: { title: 'Hire Dedicated Angular Developers in India | TalentOnLease' } },
      { path: 'hire-mean-stack-developer', component: MeanStackDeveloperComponent, data: { title: 'Hire Certified Mean Stack Developer | TalentOnLease' } },
      { path: 'hire-react-js-developer', component: ReactJsDeveloperComponent, data: { title: 'Hire ReactJS Developers | Dedicated JS Developers' } },
      { path: 'hire-mern-stack-developer', component: MernStackDeveloperComponent, data: { title: 'Hire Top Mern Stack Developer In Easy Steps' } },

      { path: 'home/:i', component: HomeComponent, data: { title: 'Best IT Staffing and Recruiting Agency - TalentOnLease' } },
      { path: 'about', component: AboutComponent, data: { title: 'About Us - TalentOnLease' } },
      { path: 'contact', component: ContactComponent, data: { title: 'IT Staffing and Recruiting Agency - Contact Us' } },
      { path: 'resources', component: ResourcesComponent },
      { path: 'find-jobs', component: FindJobsComponent, data: { title: 'Find latest Full Stack, Java, App, Python Developer Jobs' } },
      { path: 'apply-job', component: FindApplyJobComponent, data: { title: 'Find latest Full Stack, Java, App, Python Developer Jobs' } },
      { path: 'find-candidates', component: FindCandidatesComponent, data: { title: 'Hire Talented IT Candidate Online - TalentOnLease' } },
      { path: 'faqs', component: FaqsComponent },
      { path: 'privacy', component: PrivacyPolicyComponent },
      { path: 'termcondition', component: TermConditionComponent },
    ]
  },
  { path: '', resolve: { data: TenantResolver }, component: AdminlayoutComponent, loadChildren: () => import('./Components/partner/partner.module').then(m => m.PartnerModule) },
  { path: 'admin', resolve: { data: TenantResolver }, component: AdminlayoutComponent, loadChildren: () => import('./Components/admin/admin.module').then(m => m.AdminModule) },
  { path: 'client', resolve: { data: TenantResolver }, component: AdminlayoutComponent, loadChildren: () => import('./Components/client/client.module').then(m => m.ClientModule) },
  { path: 'partner', resolve: { data: TenantResolver }, component: AdminlayoutComponent, loadChildren: () => import('./Components/partner/partner.module').then(m => m.PartnerModule) },
  { path: 'executive', resolve: { data: TenantResolver }, component: AdminlayoutComponent, loadChildren: () => import('./Components/executive/executive.module').then(m => m.ExecutiveModule) },
  { path: 'reports', resolve: { data: TenantResolver }, component: AdminlayoutComponent, loadChildren: () => import('./Components/reports/reports.module').then(m => m.ReportsModule) },
  { path: 'superadmin', resolve: { data: TenantResolver }, component: AdminlayoutComponent, loadChildren: () => import('./Components/super-admin/super-admin.module').then(m => m.SuperAdminModule) },

  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'forgetPassword', component: ForgetPasswordComponent },
  { path: 'instructions/:roomId', component: InstructionsComponent },
  { path: 'sso/:sessionId/:redirectUrl', component: SSOLoginComponent },
  { path: 'sso/:sessionId', component: SSOLoginComponent },
  { path: 'recording/:roomId', component: RecordingComponent },
  { path: 'interview/:i/:r/:type', component: ConferenceComponent },

  { path: 'thankyou', component: ThankyouComponent },

  { path: '**', component: PagenotfoundComponent }
];

@NgModule({
  // imports: [RouterModule.forRoot(routes, { useHash: true })],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
