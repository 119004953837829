import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

import { Observable, map, startWith } from 'rxjs';
import { Search } from 'src/app/_models/candidates';
import { HomeService } from 'src/app/_services/home.service';
import { UserService } from 'src/app/_services/user.service';
import { JobsAvailableActionComponent } from '../../partner/jobs-available/jobs-available-action.component';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from 'ngx-webstorage';
import { RenderService } from 'src/app/_services/render.service';
import { Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ContactDialog } from '../../shared/contact-dialog/contact-dialog-component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
 
})
export class HomeComponent {
  banners: any;
  isloaded=false;
  totalClientCount:number=0;
  totalCandidatesCount: number=0;
  totalPartnerCount:  number=0;
  totaljobsPositionCount:  number=0;
  leadingCompanies: any=[];;
  faqs: any;
  technologies: any;
  reviewAndComunities: any;
  jobs: any;
  totaljobs: number=0;
  candidateSearch=new Search();
  jobsearch=new Search();
  skills: any;
  jobshow =true;
  experimence: number[];
  candidates: any;
  totalCandidate: any;
  jobCategories: any;
  candidateshow=true;
  filteredOptions!: Observable<any[]>;
  filteredjobOptions!: Observable<any[]>;
  jobControl = new FormControl('');
  myControl = new FormControl('');
  alltechnologies: any;
  // metaName='Best IT Staffing and Recruiting Agency - TalentOnLease';
  metaName='Tech Hiring & Developer Recruitment Platform | TalentOnLease';
  constructor(private homeService: HomeService,private meta:Meta,
    private authenticationService: UserService,private router:Router,private activateRoute:ActivatedRoute,
    private sessionService:LocalStorageService,public dialog: MatDialog,private renderSvc: RenderService) {
      if(environment.defaultPageUrl!='home'){
        this.router.navigate(['/'+environment.defaultPageUrl]);
        return;
      }
   
      this.getallbanners();
    this.getAlltechnologies();
    this.getallJobs(this.jobsearch);
    this.getallCandidates(this.candidateSearch);
    this.experimence=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60      ]
    let para = this.activateRoute.snapshot.params['i'];
    if (para) {
      setTimeout(() => {
        document.getElementById('spnJoin')?.click();
      }, 200);
     
    }
    setTimeout(() => {
      
        this.isloaded = true;
       
      }, 2000);

     
    }
    ngOnInit(): void {
      this.renderSvc.addLinkTag('https://talentonlease.com/');
    //this.meta.addTag({name:"title",content: 'Best IT Staffing and Recruiting Agency - TalentOnLease'});
    this.meta.addTag({name:"description",content: 'Looking to hire top tech talent? TalentOnLease, a leading tech hiring platform, connects you with skilled developers. Start building your dream team today!'});
    this.meta.addTag({name:"keywords",content: 'it staffing and recruiting companies'});
    this.meta.addTag({name:"keywords",content: 'it staffing agency'});
    this.meta.addTag({name:"robots",content: 'index,follow'});


  
    window['enablexWebBot'].init({ host: "https://dialogs.enablex.io", botId: "661edb14c84a7a6ae8092c53__lv2tjqt61pzo", }) 
      window.addEventListener('message', function(event) { 
          let request = event.data; 
          if (request.name === 'showEnablexIframe') { 
              window['enablexWebBot'].addEnablexDiv({ iFrameURL: request.iFrameURL, chatId: request.chatId }) 
          } else if (request.name === 'hideEnablexIframe')  { 
              window['enablexWebBot'].removeEnablexDiv({ chatId: request.chatId }) 
          } 
      }); 
  

    }
    
    ngOnDestroy(): void {
      this.meta.removeTag('rel="canonical"');
      this.meta.removeTag('name="title"');
      this.meta.removeTag('name="robots"');
      this.meta.removeTag('name="description"');
      this.meta.removeTag('name="keywords"');
      document.getElementById('bp-web-widget')?.remove();
    }
    
  step = 0;
  setStep(index: number) {
    this.step = index;
  }
  
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: false,
    autoplay:false,
    autoplayTimeout:5000,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      }
    },
  }
  
  forClient: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: false,
    autoplay:true,
    autoplayTimeout:5000,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      }
    },
  }
  
  forPartner: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: false,
    autoplay:true,
    autoplayTimeout:5000,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      }
    },
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  openDialog() {
    let dialogRef = this.dialog.open(ContactDialog, {
     data:{type:'N'},
       width: '700px',
     });
     dialogRef.afterClosed().subscribe(res=>{
       console.log(res)
     })
   }

  getallbanners(){   
    
    this.homeService.getBanner().subscribe(result => {   
      if(result && result.Data!=null){
       this.banners = result.Data;
       this.totalCandidatesCount= this.banners?.totalCandidatesCount;
       this.totalClientCount= this.banners?.totalClientCount;
       this.totalPartnerCount= this.banners?.totalPartnerCount;
       this.totaljobsPositionCount= this.banners?.totaljobsPositionCount;
      // this.leadingCompanies= this.banners?.leadingCompany;
       this.faqs= this.banners?.faq.slice(0,5);
       this.reviewAndComunities= this.banners?.reviewAndComunity;
      
      }
    })}
   
    private _filter(value: string): string[] {
      if(value.length==0){
        this.candidateSearch.technologyId="";
          this.getallCandidates( this.candidateSearch);
      }
      const filterValue = value.toLowerCase();
      return this.alltechnologies.filter((option: any) => option.text.toLowerCase().includes(filterValue));
    }

    private _jobfilter(value: string): string[] {
      
      if(value.length==0){
        this.jobsearch.technologyId="";
          this.getallJobs( this.jobsearch);
      }
      const filterValue = value.toLowerCase();
      
      return this.alltechnologies.filter((option: any) => option.text.toLowerCase().includes(filterValue));
    }
    getAlltechnologies(){     
      
      this.authenticationService.getTechnologies().subscribe(result => {
        if(result && result.Data!=null){
          this.alltechnologies=result.Data;
          this.technologies = result.Data.slice(0, 50);  
          this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map((value: any) => this._filter(value || '')),
          );
          this.filteredjobOptions = this.jobControl.valueChanges.pipe(
            startWith(''),
            map((value: any) => this._jobfilter(value || '')),
          );
        }
        
      })
     
      }
      getAllPrimarySkills(){ 
this.authenticationService.getprimarySkills().subscribe(result => {
          if(result && result.Data!=null){
            this.skills = result.Data.slice(0, 50);          
          }          
        })
        }
        applyJob(job:any,technologyId:any){
          const userDetail =  this.sessionService.retrieve('userDetail');
    
          if(userDetail!=null && userDetail.Data !=undefined)
          {
            this.sessionService.store("jobApplyFilter",technologyId);
           // this.router.navigate(['/jobs',job]);
            this.openDialogApply(job);
          }
          else{
            this.router.navigate(['/login']);
          }
        }
    
        openDialogApply(id:any) {
          const dialogRef= this.dialog.open(JobsAvailableActionComponent,{
             width: '1100px', height: '650px',
             disableClose: true,
             data:{    
               id:id   
           }
           });
          }
        viewAlljobs(){
          const userDetail =  this.sessionService.retrieve('userDetail');
          if(userDetail!=null && userDetail.Data !=undefined)
          {        
            this.router.navigate(['/jobs','home']);
          }
          else{
            this.router.navigate(['/login']);
          }
        }
      getallJobs(model:any){   
        

        this.sessionService.store('jobFilter', model);
        this.homeService.getJobs(model).subscribe(result => {
          this.jobs=null;
          this.totaljobs=0;
          this.jobshow =true;
          if(result && result.data!=null && result.data.length>0){
            this.jobs = result.data; 
            this.jobCategories= result.data.slice(0, 50);  
            this.totaljobs=result.totalCount ;
          }
          else{
            this.jobshow =false;
          }
          
        })}

        selectedCandidate(model:any){
          let   searchValues =null;
          if(!isNaN(parseInt(model.value))){
            searchValues =model.value;
          }
          else{
           
            const job= this.technologies.find((option: any) => option.text.toLowerCase()==(model.value.toLowerCase()));
            if(job !=null && job !=undefined){
              searchValues =job.value;
            }
            else{
              searchValues=model.value;
            }
          }
        
        this.candidateSearch.technologyId= searchValues;
          this.getallCandidates( this.candidateSearch);
        }
        spaceValidation(e:any): boolean {
          const charCode = e.which ? e.which : e.keyCode;
          if (charCode ==32) {
            return false
          }
          return true
        }
        selectedJob(model:any){
            let   searchValues =null;
            if(!isNaN(parseInt(model.value))){
              searchValues =model.value;
            }
            else{
             
              const job= this.technologies.find((option: any) => option.text.toLowerCase()==(model.value.toLowerCase()));
              if(job !=null && job !=undefined){
                searchValues =job.value;
              }
              else{
                searchValues=model.value;
              }
            }
          
          this.jobsearch.technologyId= searchValues;
          this.getallJobs( this.jobsearch);
        }
        getallCandidates(model:any){  
          
          this.sessionService.store('candidateFilter', model); 
          this.homeService.getCandidates(model).subscribe(result => {
            if(result && result.data!=null && result.data.length>0){
              this.candidates = result.data;  
              this.totalCandidate=result.totalCount;
              this.candidateshow =true;
            }
            else{
              this.totalCandidate=0;
              this.candidateshow=false;
            }
           
          })}

          CandidateDetail(candidateId:any){
            const userDetail =  this.sessionService.retrieve('user');
            if(userDetail!=null && userDetail.Data !=undefined)
            {
              this.router.navigate(['/candidateDetail',candidateId]);
            }
            else{
              this.router.navigate(['/login']);
            }
          }
         
}
